import { useState } from 'react';
import { useJwtToken, UserContext } from './hooks/auth';
import { ErrorBoundary } from "react-error-boundary";
import { LoginForm } from './pages/LoginForm/LoginForm';
import axios from "axios";
import { CustomRouter } from './pages/CustomRouter';
import { InGameOverlay } from './overlays/Ingame/Ingame';

function App() {

  const { token, setToken } = useJwtToken();
  const [nickname, setNickname] = useState("");

  const searchParams = new URLSearchParams(window.location.search);

  if(searchParams.has("stream")) {
    if(searchParams.get("overlay") == "ingame") {
      return <InGameOverlay />
    }
    return <div className='alert alert-danger'>Not found</div>
  }

  const logout = () => {
    setToken(null);
  }

  if (!token) {
    return <LoginForm token={"abc"} setToken={setToken}/>
  }

  if(token) {
    axios.post("/backend/auth/verify", { token }).then(res => {
      if(!res.data.valid){
        logout();
      }

      setNickname(res.data.username);
    }).catch(err => {
      logout();
    })
  }

  return (
    <UserContext.Provider value={{ token, setToken, logout, nickname }}>
        <ErrorBoundary fallback={<Error />}>
          <CustomRouter />
        </ErrorBoundary>
    </UserContext.Provider>
  );
}

const Error = () => {
  return <div className="alert alert-danger m-2">
    <h4 class="alert-heading">Something went wrong</h4>
    <hr />
    <p className="mb-0">An Error occured, please try again or if this happens again please contact the admin.</p>
  </div>
}

export default App;
