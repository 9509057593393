import React, { useEffect, useState } from "react";
import { usePlayers } from "../../hooks/players";
import { useTournaments } from "../../hooks/tournaments";
import moment from "moment";
import axios from "axios";
import { UserContext } from "../../hooks/auth";
import { PokemonDataContext } from "../../hooks/pokemon";
import _ from "lodash";
import { Link } from "react-router-dom";

const Types = [
  "Normal", "Fire", "Water", "Electric", "Grass", "Ice", "Fighting", "Poison", "Ground", "Flying", "Psychic", "Bug", "Rock", "Ghost", "Dragon", "Dark", "Steel", "Fairy", "Stellar"
]

export const TeamBuilder = () => {

  const [player, setPlayer] = useState(undefined)
  const [tournament, setTournament] = useState(undefined)
  const { token } = React.useContext(UserContext)
  const [show, setShow] = useState(false)
  const [teamId, setTeamId] = useState(undefined)

  useEffect(() => {
    if(player && tournament) {
      axios.get(`/backend/builder/teams/${player.id}/${tournament.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        console.log(response.data)
        if(response.data.found) {
          setTeamId(response.data.team_id)
          setShow(false)
        } else {
          setShow(true)
          setTeamId(undefined)
        }
      }).catch(error => {
        console.log(error)
        setShow(false)
      })
    }
  }, [player, tournament])

  if(teamId) {
    return  <div className="row mt-2">
      <div className="col-12"><div className="alert alert-success">
      <h4>Team already exists</h4>
      <p className="mb-0"><i className="fas fa-check fa-fw me-2"></i>The team for this player and tournament already exists.</p>
      <Link to={`/teams/${teamId}/edit`} className="btn btn-primary mt-2">View Team</Link>
    </div></div></div>
  }

  return <div className="row mt-2">
    <div className="col-12 col-xxl-4">
      <GeneralData setPlayer={setPlayer} setTournament={setTournament} player={player} tournament={tournament} />
    </div>
    <div className="col-12 col-xxl-8">
      {show ? <PokemonTeamBuilder player={player} tournament={tournament} /> : undefined}
    </div>
  </div>
}

const PokemonTeamBuilder = ({ player, tournament }) => {

  const [pokemon1, setPokemon1] = useState(undefined)
  const [pokemon2, setPokemon2] = useState(undefined)
  const [pokemon3, setPokemon3] = useState(undefined)
  const [pokemon4, setPokemon4] = useState(undefined)
  const [pokemon5, setPokemon5] = useState(undefined)
  const [pokemon6, setPokemon6] = useState(undefined)

  const [showImport, setShowImport] = useState(false)
  const [uploading, setUploading] = useState(false)
  const { token } = React.useContext(UserContext)

  const saveTeam = () => {
    console.log("Save Team")
    console.log("Pomemon 1", pokemon1)
    console.log("Pomemon 2", pokemon2)
    console.log("Pomemon 3", pokemon3)
    console.log("Pomemon 4", pokemon4)
    console.log("Pomemon 5", pokemon5)
    console.log("Pomemon 6", pokemon6)

    let team = {
      playerId: player.id,
      tournamentId: tournament.id,
      pokemon1: pokemon1,
      pokemon2: pokemon2,
      pokemon3: pokemon3,
      pokemon4: pokemon4,
      pokemon5: pokemon5,
      pokemon6: pokemon6
    }

    if(uploading) return;
    setUploading(true)

    axios.post("/backend/builder/save", team, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      console.log("RESPONSE", response.data)
      setPokemon1(undefined)
      setPokemon2(undefined)
      setPokemon3(undefined)
      setPokemon4(undefined)
      setPokemon5(undefined)
      setPokemon6(undefined)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setUploading(false)
    })
  }

  console.log("Pomemon 1", pokemon1)

  if(uploading) {
    return <div className="alert alert-warning">
      <h4>Uploading</h4>
      <p><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Please wait while the team is uploaded.</p>
    </div>
  }

  return <div className="row">
    <div className="col-12">
      <div className="alert alert-primary">
        <h4>Team Builder</h4>
        <p className="mb-0">Build the team for the stream overlay or import it via standard format.</p>
      </div>
    </div>
    <div className="col-12 text-end">
      {!showImport && <button className="btn btn-success" onClick={() => setShowImport(true)}>Start Import</button>}
    </div>
    <div className="col-12">
      {showImport && <Import setPokemon1={setPokemon1} setPokemon2={setPokemon2} setPokemon3={setPokemon3} setPokemon4={setPokemon4} setPokemon5={setPokemon5} setPokemon6={setPokemon6} setShowImport={setShowImport} />}
    </div>
    <div className="col-12 my-3">
      <div className="row">
        <PokemonData>
          <Pokemon index={1} pokemon={pokemon1} setPokemon={setPokemon1} />
          <Pokemon index={2} pokemon={pokemon2} setPokemon={setPokemon2} />
          <Pokemon index={3} pokemon={pokemon3} setPokemon={setPokemon3} />
          <Pokemon index={4} pokemon={pokemon4} setPokemon={setPokemon4} />
          <Pokemon index={5} pokemon={pokemon5} setPokemon={setPokemon5} />
          <Pokemon index={6} pokemon={pokemon6} setPokemon={setPokemon6} />
        </PokemonData>
        <div className="co-12">
          { (pokemon1 && pokemon2 && pokemon3 && pokemon4 && pokemon5 && pokemon6) && <button className="btn btn-primary" onClick={saveTeam}>Save Team</button>}
        </div>
      </div>
    </div>
  </div>
}

export const PokemonData = ({ ...props }) => {

  const [refresh, setRefresh] = useState(new Date().toISOString())
  const [pokemons, setPokemons] = useState([])
  const [items, setItems] = useState([])
  const [abilities, setAbilities] = useState([])
  const [moves, setMoves] = useState([])
  const { token } = React.useContext(UserContext)

  const fetchPokemons = () => {
    axios.get("/backend/pokemon/data/pokemons", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setPokemons(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchItems = () => {
    axios.get("/backend/pokemon/data/items", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setItems(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchAbilities = () => {
    axios.get("/backend/pokemon/data/abilities", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setAbilities(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchMoves = () => {
    axios.get("/backend/pokemon/data/moves", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setMoves(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(fetchPokemons, [])
  useEffect(fetchItems, [])
  useEffect(fetchAbilities, [])
  useEffect(fetchMoves, [])

  if(pokemons.length === 0 || items.length === 0 || abilities.length === 0 || moves.length === 0) {
    return <div className="col-12"><div className="alert alert-info">
      <i className="fas fa-spin fa-fw fa-spinner me-2"></i>
      Pokemon Data is being downloaded
    </div>
    </div>
  }

  return <PokemonDataContext.Provider value={{ pokemons: pokemons, items: items, abilities: abilities, moves: moves }}>
    {props.children}
    <div className="col-12 d-none">
      <button className="btn btn-primary" onClick={() => setRefresh(new Date().toISOString())}>Refresh</button>
    </div>
  </PokemonDataContext.Provider>
}

const Import = ({ setShowImport, setPokemon1, setPokemon2, setPokemon3, setPokemon4, setPokemon5, setPokemon6 }) => {

  const DEFAULT = `Dragonite @ Leftovers
Ability: Inner Focus
Level: 50
Tera Type: Normal
- Extreme Speed
- Outrage
- Aerial Ace
- Stomping Tantrum


Chien-Pao @ Focus Sash  
Ability: Sword of Ruin
Level: 50
Tera Type: Ghost
- Ice Spinner
- Sacred Sword
- Sucker Punch
- Protect


Flutter Mane @ Life Orb  
Ability: Protosynthesis
Level: 50
Tera Type: Fairy
- Moonblast
- Dazzling Gleam
- Shadow Ball
- Protect


Entei @ Sitrus Berry
Ability: Inner Focus
Level: 50
Tera Type: Grass
- Sacred Fire
- Extreme Speed
- Stomping Tantrum
- Snarl


Ogerpon-Wellspring @ Choice Scarf  
Ability: Water Absorb
Level: 50
Tera Type: Water
- Ivy Cudgel
- Horn Leech
- Follow Me
- Spiky Shield


Raging Bolt @ Covert Cloak  
Ability: Protosynthesis
Level: 50
Tera Type: Water
- Thunderbolt
- Thunderclap
- Draco Meteor
- Protect
  `
  const [content, setContent] = useState(DEFAULT);
  const rowCount = content.split("\n").length
  const { token } = React.useContext(UserContext)
  const [importing, setImporting] = useState(false)

  const startImport = () => {
    if(importing) return;
    setImporting(true)
    axios.post("/backend/builder/import", { content }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      console.log(response.data)

      for (let index in response.data) {
        let pkmn = response.data[index];
        if (index == 0) setPokemon1(pkmn)
        if (index == 1) setPokemon2(pkmn)
        if (index == 2) setPokemon3(pkmn)
        if (index == 3) setPokemon4(pkmn)
        if (index == 4) setPokemon5(pkmn)
        if (index == 5) setPokemon6(pkmn)
        setShowImport(false)
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setImporting(false)
    })
  }

  if(importing) {
    return <div className="alert alert-warning mb-0">
      <h4>Importing</h4>
      <p><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Please wait while the import is processed.</p>
    </div>
  }

  return <div className="card">
    <div className="card-header">Import</div>
    <div className="card-body">
      <textarea className="form-control mb-3" rows={rowCount} value={content} onChange={(event) => setContent(event.target.value)}></textarea>
      <button className="btn btn-success" onClick={startImport}>Import</button>
      <button className="btn btn-danger ms-2" onClick={() => {
        setShowImport(false)
        setContent("")
      }}>Cancel</button>
    </div>
  </div>
}

const GeneralData = ({ setPlayer, setTournament, player, tournament }) => {

  const players = usePlayers()
  const tournaments = useTournaments()

  const changePlayer = (event) => {
    let playerId = event.target.value;
    let player = players.find(player => player.id === playerId)

    setPlayer(player);
  }


  const changeTournament = (event) => {
    let tournamentId = event.target.value;
    let tournament = tournaments.find(tournament => tournament.id === tournamentId)

    setTournament(tournament)
  }

  return <div className="card">
    <div className="card-header">General Data</div>
    <div className="card-body">
      <CustomSelect label="Player" options={formatPlayers(players)} onChange={changePlayer} />
      <CustomSelect label="Tournament" options={formatTournaments(tournaments)} onChange={changeTournament} last={true} />
    </div>
    <div className="card-footer">
      {!player ? <span className="badge bg-danger me-2">Player not selected</span> : <span className="badge bg-success me-2">Player selected</span>}
      {!tournament ? <span className="badge bg-danger">Tournament not selected</span> : <span className="badge bg-success">Tournament selected</span>}
    </div>
  </div>
}

const CustomSelect = ({ prefix, label, options, value, onChange, last = false }) => {
  return <div className={"form-floating " + (last ? "" : "mb-3")}>
    <select className="form-select" onChange={onChange} value={value}>
      <option value="">Select {label}</option>
      {options.map((option, index) => <option key={prefix + "_" + option.id + "_" + option.name + "_" + index} value={option.id}>{option.name}</option>)}
    </select>
    <label>{label}</label>
  </div>
}

const formatPlayers = (players) => {
  return players.map(player => {
    return {
      id: player.id,
      name: `${player.nickname} (${player.firstname} / ${player.country})`
    }
  })
}
const formatTournaments = (tournaments) => {
  return tournaments.map(tournament => {
    return {
      id: tournament.id,
      name: `${tournament.name} @ ${tournament.location} (${moment(tournament.date).format("DD/MM/YYYY")})`
    }
  })
}

export const Pokemon = ({ index, pokemon, setPokemon }) => {
  const { pokemons, items, abilities, moves } = React.useContext(PokemonDataContext)

  if (!pokemon) {
    return <div className="col-4"><div className="alert alert-danger">No Pokemon present</div></div>;
  }

  const moveId1 = pokemon.moves[0].toLowerCase().replaceAll(" ", "-").trim()
  const moveId2 = pokemon.moves[1].toLowerCase().replaceAll(" ", "-").trim()
  const moveId3 = pokemon.moves[2].toLowerCase().replaceAll(" ", "-").trim()
  const moveId4 = pokemon.moves[3].toLowerCase().replaceAll(" ", "-").trim()

  let pkmn = pokemons.find(a => a.id === pokemon.id)
  let item = items.find(a => a.name === pokemon.item)
  let ability = abilities.find(a => a.name === pokemon.ability)
  let move1 = moves.find(a => a.name === moveId1)
  let move2 = moves.find(a => a.name === moveId2)
  let move3 = moves.find(a => a.name === moveId3)
  let move4 = moves.find(a => a.name === moveId4)
  let tera = pokemon.tera;
  let shiny = pokemon.shiny;
  let shinyPrefix = shiny ? "/shiny" : ""

  let itemName = item.name;

  let imgPrefix = "http://localhost:3333"

  if (process.env.NODE_ENV === 'production') {
    imgPrefix = ""
  }

  let image = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/home${shinyPrefix}/${pkmn?.id}.png`
  let itemImage = `${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${itemName}.png`
  let teraImage = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/TeraKristall/${tera.toLowerCase()}.png`

  const changePokemon = (event) => {
    let newpkmn = { ...pokemon }
    newpkmn.id = Number(event.target.value)
    setPokemon(newpkmn)
  }

  const changeItem = (event) => {
    let newpkmn = { ...pokemon }
    newpkmn.item = event.target.value
    setPokemon(newpkmn)
  }

  const changeTera = (event) => {
    let newpkmn = { ...pokemon }
    newpkmn.tera = event.target.value
    setPokemon(newpkmn)
  }

  const changeMove = (index, move) => {
    let newpkmn = { ...pokemon }
    newpkmn.moves[index] = move
    setPokemon(newpkmn)
  }

  const changeShiny = (event) => {
    let newpkmn = { ...pokemon }
    newpkmn.shiny = !shiny
    setPokemon(newpkmn)
  }

  const changeAbility = (event) => {
    let newpkmn = { ...pokemon }
    newpkmn.ability = event.target.value
    setPokemon(newpkmn)
  }

  return <div className="col-4">
    <div className="card mb-3">
      <div className="card-img-top position-relative text-center">
        <img src={image} className="card-img-top p-4" style={{maxWidth: "200px"}}/>
        <div className="item-preivew" style={{ position: "absolute", top: "20px", left: "20px", height: "60px", width: "60px", display: "flex", alignContent: "center", alignItems: "center" }}>
           {tera && <img src={teraImage} alt="" className="img-fluid" />}
        </div>
        <div className="item-preivew" style={{ position: "absolute", bottom: "20px", right: "20px", height: "40px", width: "40px", display: "flex", alignContent: "center", alignItems: "center" }}>
          {item && <img src={itemImage} alt="" className="img-fluid" />}
        </div>
      </div>
      <div className="card-body border-top">
        <CustomSelect prefix={index} label="Pokemon" options={pokemons.map(pkmn => { return { id: pkmn.id, name: pkmn.name } })} value={pkmn.id} onChange={changePokemon} />
        <CustomSelect prefix={index} label="Ability" options={abilities.map(ability => { return { id: ability.name, name: ability.name } })} value={ability?.name} onChange={changeAbility}/>
        <hr />
        <CustomSelect prefix={index + "_mv1"} label="Move 1" options={moves.map(ability => { return { id: ability.name, name: ability.name } })} value={move1?.name} onChange={(event) => { changeMove(0, event.target.value) }} />
        <CustomSelect prefix={index + "_mv2"} label="Move 2" options={moves.map(ability => { return { id: ability.name, name: ability.name } })} value={move2?.name} onChange={(event) => { changeMove(1, event.target.value) }} />
        <CustomSelect prefix={index + "_mv3"} label="Move 3" options={moves.map(ability => { return { id: ability.name, name: ability.name } })} value={move3?.name} onChange={(event) => { changeMove(2, event.target.value) }} />
        <CustomSelect prefix={index + "_mv4"} label="Move 4" options={moves.map(ability => { return { id: ability.name, name: ability.name } })} value={move4?.name} onChange={(event) => { changeMove(3, event.target.value) }} />
        <hr />
        <CustomSelect prefix={index} label="Item" options={items.map(item => { return { id: item.name, name: item.name } })} value={item.name} onChange={changeItem} />
        <CustomSelect prefix={index} label="Tera" options={Types.map(type => { return { id: type, name: type } })} value={tera} onChange={changeTera} last={true} />
        <hr />
        <button onClick={changeShiny} className={"btn btn-block w-100 " + (shiny ? "btn-success" : "btn-danger")}>{shiny ? "Shiny" : "Not Shiny"}</button>
      </div>
    </div>
  </div>

  return <div className="col-12 col-xxl-6">
    <div className="alert alert-danger">
      <img src={image} alt="" height={"100px"} width={"100px"} />
      {item && <img src={itemImage} alt="" height={"50px"} width={"50px"} />}
      <pre className="d-none">{JSON.stringify([pokemon, pkmn], null, 2)}</pre>
    </div>
  </div>
}