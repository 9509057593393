import React, { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts'
import axios from 'axios';
import { UserContext } from '../../hooks/auth';
import _ from 'lodash';
import { PokemonData } from '../Teams/TeamBuilder';
import { PokemonDataContext } from '../../hooks/pokemon';
import { set } from 'react-hook-form';

export const Stream = () => {


  const [match, setMatches] = useState(undefined);
  const { token } = React.useContext(UserContext);
  const config =  match?.match.config;

  useEffect(() => {
    axios.get("/backend/stream", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setMatches(response.data)
    }).catch(error => {
      console.error(error)
    })
  }, [])

  if (!match) {
    return <div className="row mt-2">
      <div className="col-12">
        <div className="alert alert-warning"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Loading...</div>
      </div>
    </div>
  }

  return <div className='row mt-2'>
    <div className='col-6'>
      <GeneralSettings match={match.match} config={config} callback={setMatches}/>
    </div>
    <div className='col-6'>
      <OverlaySettings config={config} callback={setMatches}/>
    </div>
    <div className='col-12'>
      <PokemonData>
        <StreamSettings m={match} callback={setMatches}/>
      </PokemonData>
    </div>
  </div>
}

const StreamSettings = ({ m, callback }) => {
  const match = m.match;
  const team1 = m.team1;
  const team2 = m.team2;

  const playerLeft = match.playerLeft;
  const playerRight = match.playerRight;

  const config = match.config;

  return <div className='row'>
    <div className='col-6'>
      <div className='card mb-3'><div className='card-body p-2 text-center'>{playerLeft?.nickname} - ({match.scoreLeft})</div></div>
      {_.times(6, (i) => {
        return <PokemomInfo key={i} team={1} number={i + 1} pokemon={team1["pokemon" + (i + 1)]} config={config} callback={callback} />
      })}
    </div>
    <div className='col-6'>
      <div className='card mb-3'><div className='card-body p-2 text-center'>{playerRight?.nickname} - ({match.scoreRight})</div></div>
      {_.times(6, (i) => {
        return <PokemomInfo key={i} team={2} number={i + 1} pokemon={team2["pokemon" + (i + 1)]} config={config} reverse callback={callback} />
      })}
    </div>
    <div className='col-12 mt-3'>
      <div className='alert alert-info'><pre>{JSON.stringify(m, null, 2)}</pre></div>
    </div>
  </div>
}

const OverlaySettings = ({config, callback}) => {
  const { token } = React.useContext(UserContext)

  const reset = () => {
    axios.post("/backend/stream/reset", {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      //console.log(response.data)
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <div className='card'>
    <div className='card-body p-2'>
      <ShowOverlayButton config={config} callback={callback}/>
      <button onClick={reset} className='btn btn-warning btn-sm w-100'>RESET</button>
    </div>
  </div>
}

const ShowOverlayButton = ({config, callback}) => {
  let show = config.ingame_overlay;
  if (show == undefined) show = false;

  const { token } = React.useContext(UserContext)

  const toggleShow = () => {
    axios.post("/backend/stream/ingame/overlay", {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <button onClick={toggleShow} className={'btn btn-sm w-100 mb-2 ' + (show ? "btn-success" : "btn-danger")}>{show ? <span><i className='fas fa-eye fa-fw me-2'></i>Overlay visible</span> : <span><i className='fas fa-eye-slash me-2 fa-fw'></i>Overlay Hidden</span>}</button>
}

const GeneralSettings = ({ match, callback}) => {

  const types = [
    { name: "Best Of 1", id: "bo1" },
    { name: "Best Of 3", id: "bo3" },
    { name: "Best Of 5", id: "bo5" },
    { name: "Swiss", id: "swiss" },
  ]

  const { token } = React.useContext(UserContext)

  const [mode, setMode] = useState(match.mode)
  const [scoreLeft, setScoreLeft] = useState(match.scoreLeft)
  const [scoreRight, setScoreRight] = useState(match.scoreRight)

  const [swissWinsLeft, setSwissWinsLeft] = useState(match.swissWinsLeft)
  const [swissWinsRight, setSwissWinsRight] = useState(match.swissWinsRight)

  const [swissDrawsLeft, setSwissDrawsLeft] = useState(match.swissDrawsLeft)
  const [swissDrawsRight, setSwissDrawsRight] = useState(match.swissDrawsRight)

  const [swissLossesLeft, setSwissLossesLeft] = useState(match.swissLossesLeft)
  const [swissLossesRight, setSwissLossesRight] = useState(match.swissLossesRight)

  const updateMatch = () => {
    axios.post("/backend/stream/settings", {
      mode: mode,
      scoreLeft: scoreLeft,
      scoreRight: scoreRight,
      swissWinsLeft: swissWinsLeft,
      swissWinsRight: swissWinsRight,
      swissDrawsLeft: swissDrawsLeft,
      swissDrawsRight: swissDrawsRight,
      swissLossesLeft: swissLossesLeft,
      swissLossesRight: swissLossesRight
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <div className='row mb-3'>
    <div className='col-12'>
      <div className='card'>
        <div className='card-header'>SETTINGS ({match.mode})</div>
        <div className='card-body p-2'>
          <CustomSelect prefix="match" label="Type" options={types} value={mode} onChange={(e) => { setMode(e.target.value) }} />
          <div className='row'>
            <div className='col-6'><CustomIntegerInput prefix="match" label="Score Left" value={scoreLeft} onChange={(e) => { setScoreLeft(e.target.value) }} /></div>
            <div className='col-6'><CustomIntegerInput prefix="match" label="Score Right" value={scoreRight} onChange={(e) => { setScoreRight(e.target.value) }} /></div>
          </div>
          <button onClick={updateMatch} className='btn btn-primary btn-sm w-100'>Save</button>
        </div>
      </div>
    </div>
  </div >
}

const CustomIntegerInput = ({ prefix, label, value, onChange, last = false }) => {
  return <div className={"form-floating " + (last ? "" : "mb-3")}>
    <input type="number" className="form-control" onChange={onChange} value={value} min={0} />
    <label>{label}</label>
  </div>
}

const CustomSelect = ({ prefix, label, options, value, onChange, last = false }) => {
  return <div className={"form-floating " + (last ? "" : "mb-3")}>
    <select className="form-select" onChange={onChange} value={value}>
      <option value="">Select {label}</option>
      {options.map((option, index) => <option key={prefix + "_" + option.id + "_" + option.name + "_" + index} value={option.id}>{option.name}</option>)}
    </select>
    <label>{label}</label>
  </div>
}

const PokemomInfo = ({ pokemon, config, reverse, team, number, callback }) => {
  const { pokemons, items, abilities, moves } = React.useContext(PokemonDataContext)

  let pkmn = pokemons.find(a => a.id === pokemon.id)
  let item = items.find(a => a.name === pokemon.item)
  let tera = pokemon.tera;
  let shiny = pokemon.shiny;
  let shinyPrefix = shiny ? "/shiny" : ""

  let itemName = item.name;

  let imgPrefix = "http://localhost:3333"

  if (process.env.NODE_ENV === 'production') {
    imgPrefix = ""
  }

  let image = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/home${shinyPrefix}/${pkmn?.id}.png`
  let itemImage = `${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${itemName}.png`
  let teraImage = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/TeraKristall/${tera.toLowerCase()}.png`

  return <div className={'row mb-3 ' + (reverse ? "flex-row-reverse" : "")}>
    <div className='col-2'>
      <div className='card position-relative'>
        <img src={image} className="card-img-top p-4" />
        <div className="item-preivew" style={{ position: "absolute", top: "10px", left: "10px", height: "40px", width: "40px", display: "flex", alignContent: "center", alignItems: "center" }}>
          {tera && <img src={teraImage} alt="" className="img-fluid" />}
        </div>
        <div className="item-preivew" style={{ position: "absolute", bottom: "10px", right: "10px", height: "30px", width: "30px", display: "flex", alignContent: "center", alignItems: "center" }}>
          {item && <img src={itemImage} alt="" className="img-fluid" />}
        </div>
      </div>
    </div>
    <div className='col'>
      <div className='card'>
        <div className='card-body p-2'>
          <div className="d-flex mb-2" style={{ gap: "5px" }}>
            <HiddenButton team={team} number={number} config={config} callback={callback}/>
            {true && <ActiveButton team={team} number={number} config={config} callback={callback} />}
            <TeraButton team={team} number={number} config={config} callback={callback} />
            <FaintedButton team={team} number={number} config={config} callback={callback} />
          </div>
          <StatusButtons team={team} number={number} config={config} callback={callback} />
          <OrderButtons team={team} number={number} config={config} callback={callback} />
        </div>
      </div>
    </div>
  </div>
}

const ActiveButton = ({ team, number, config, callback }) => {
  const { token } = React.useContext(UserContext)
  let active = config["active_" + team + "_" + number];
  if (active == undefined) active = false;

  const toggleActive = () => {
    axios.post(`/backend/stream/active/${team}/${number}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <button onClick={toggleActive} className={"btn btn-sm " + (active ? "btn-success" : "btn-secondary")}>{active ? <span><i className='fas fa-check fa-fw me-2'></i>Active</span> : <span><i className='fas fa-times fa-fw me-2'></i>Not Active</span>}</button>
}

const FaintedButton = ({ team, number, config, callback}) => {

  const { token } = React.useContext(UserContext)
  let fainted = config["fainted_" + team + "_" + number];
  if (fainted == undefined) fainted = false;

  const toggleFainted = () => {
    axios.post(`/backend/stream/fainted/${team}/${number}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <button onClick={toggleFainted} className={'btn btn-sm ' + (fainted ? "btn-danger" : "btn-success")}>{fainted ? <span><i className='fas fa-skull fa-fw me-2'></i>Fainted</span> : <span><i className='fas fa-arrow-up fa-fw me-2'></i>Alive</span>}</button>
}

const TeraButton = ({ team, number, config, callback}) => {

  const tera = config["tera_" + team] == number;
  const { token } = React.useContext(UserContext)

  const setTera = () => {
    axios.post(`/backend/stream/tera/${team}/${number}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <button onClick={setTera} className={"btn btn-sm " + (tera ? "btn-success" : "btn-secondary")}>{tera ? <span><i className='fas fa-check fa-fw me-2'></i>Tera</span> : <span><i className='fas fa-times fa-fw me-2'></i>Tera</span>}</button>
}

const HiddenButton = ({ team, number, config, callback}) => {
  const { token } = React.useContext(UserContext)
  let hidden = config["hidden_" + team + "_" + number]
  if (hidden == undefined) hidden = true;

  const toggleHidden = () => {
    axios.post(`/backend/stream/hidden/${team}/${number}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <button onClick={toggleHidden} className={'btn btn-sm ' + (hidden ? "btn-secondary" : "btn-success")}>{hidden ? <span><i className='fas fa-eye-slash fa-fw me-2'></i>Hidden</span> : <span><i className="fas fa-eye fa-fw me-2"></i>Visible</span>}</button>
}

const StatusButtons = ({ team, number, config, callback}) => {

  const availableStatuses = [
    "None",
    "Burn",
    "Paralyze",
    "Poison",
    "Sleep",
    "Frozen",
    "Confused"
  ]

  let status = config["status_" + team + "_" + number]
  if(status == undefined) status = "none";

  const { token } = React.useContext(UserContext)
  const setStatus = (status) => {
    axios.post(`/backend/stream/status/${team}/${number}/${status}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <div className='btn-group w-100 mb-2'>
    <span className='btn btn-primary btn-sm disabled'>Status</span>
    {availableStatuses.map((a, index) => {
      let key = a.toLowerCase();
      return <button key={index} className={"btn btn-sm " + ((key === status) ? "btn-success" : "btn-secondary")} onClick={() => setStatus(key)}>{a}</button>
    })}
  </div>
}

const OrderButtons = ({ team, number, config, callback }) => {
  const { token } = React.useContext(UserContext)

  const setOrder = (order) => {
    axios.post(`/backend/stream/order/${team}/${number}/${order}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      callback(response.data)
    }).catch(error => {
      console.error(error)
    })
  }

  return <div className='btn-group w-100'>
    <span className='btn btn-primary btn-sm disabled'>Order</span>
    <button onClick={() => setOrder(1)} className={'btn btn-sm ' + (config["order_" + team + "_" + 1] == number ? "btn btn-success" : "btn-secondary")}>1</button>
    <button onClick={() => setOrder(2)} className={'btn btn-sm ' + (config["order_" + team + "_" + 2] == number ? "btn btn-success" : "btn-secondary")}>2</button>
    <button onClick={() => setOrder(3)} className={'btn btn-sm ' + (config["order_" + team + "_" + 3] == number ? "btn btn-success" : "btn-secondary")}>3</button>
    <button onClick={() => setOrder(4)} className={'btn btn-sm ' + (config["order_" + team + "_" + 4] == number ? "btn btn-success" : "btn-secondary")}>4</button>
  </div>
}